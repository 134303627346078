<template>
  <the-layout :title="title">
    <login-form />
  </the-layout>
</template>

<script>
import { useI18n } from "vue-i18n";

import TheLayout from "../components/layout/TheLayout";
import LoginForm from "../components/login/LoginForm";

export default {
  name: "Login",
  components: {
    LoginForm,
    TheLayout,
  },
  setup() {
    const { t } = useI18n();
    return {
      title: t("login.title"),
    };
  },
};
</script>
